import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MediaModel } from '../../models/media.model';
import { ApiImagePipe } from '../../pipes/image.pipe';
import { SanitizeHtmlPipe } from '../../pipes/safe-html.pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-inner-html',
  templateUrl: './inner-html.component.html',
  styleUrls: ['./inner-html.component.scss'],
  imports: [SanitizeHtmlPipe, CommonModule],
  standalone: true,
})
export class InnerHtmlComponent implements OnChanges {
  @Input() html?: string = '';
  @Input() center: boolean = false;
  @Input() allowLinks: boolean = true;

  apiDomain = environment.apiDomain;
  constructor(
    private router: Router,
    private apiImagePipe: ApiImagePipe,
  ) {}

  ngOnChanges(): void {
    if (this.html) {
      this.html = this.html.replaceAll(
        '../upload/editor/',
        this.apiDomain + 'editor/',
      );

      const imgTags = this.html.match(/<img [^>]*src="[^"]*"[^>]*>/gm);

      if (imgTags) {
        for (let i = 0; i < imgTags.length; i++) {
          const element = imgTags[i];

          if (element) {
            let source = element
              .match(/src="[^"]*"[^>]/gm)
              ?.toString()
              .split('"')[1];

            const elementClass = element
              .match(/class="[^"]*"[^>]/gm)
              ?.toString()
              .split('"')[1];

            const elementCaption = element
              .match(/alt="[^"]*"[^>]/gm)
              ?.toString()
              .split('"')[1];

            if (source) {
              let image = new MediaModel();
              image.Url = source
                ?.replace(this.apiDomain, '')
                .replace('upload/editor/', 'editor');
              image.PageType = 0;
              image.Caption = elementCaption ?? 'Reiser.no';
              image.ImageRatio = 1;

              const newImageUrl = this.apiImagePipe.transform(
                image.Url,
                elementClass === 'full-width' ? 1200 : 585,
                image,
                'fit',
              );

              this.html = this.html.replace(source, newImageUrl ?? image.Url);
            }
          }
        }
      }
    }
  }

  processLinks(e: any) {
    e.preventDefault();
    const element: HTMLElement = e.target;
    const parentElement: any = e.target.parentNode;

    let link: string = '';
    if (element.nodeName === 'A') {
      e.preventDefault();
      link = element.getAttribute('href') ?? '';
    } else if (parentElement && parentElement.nodeName === 'A') {
      link = parentElement.getAttribute('href') ?? '';
    }

    if (link !== '') {
      if (link?.substr(0, 4) != 'http') {
        this.router.navigate([link]);
        return;
      }
      if (
        element.getAttribute('target') &&
        element.getAttribute('target') === '_blank'
      ) {
        window.open(link, '_blank');
      } else {
        window.location.href = link;
      }
    }
    e.preventDefault(false);
  }

  extractUrlValue(url: string, key: string) {
    var match = url.match(`${key}=([^&]+)`);
    return match ? match[1] : null;
  }
}
