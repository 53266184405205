import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '../services/settings.service';

@Pipe({ name: 'translate', standalone: true })
export class TranslationPipe implements PipeTransform {
  constructor(private settingService: SettingsService) {}

  transform(translationId: number) {
    let translation = this.settingService.getTranslation(translationId);

    if (translation) {
      return translation;
    }

    return '';
  }
}
