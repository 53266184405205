import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Pipe({ name: 'sanitizeHtml', standalone: true })
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    if (value) {
      var find = 'Upload/editor-image';
      var re = new RegExp(find, 'g');

      value = value.replace(re, environment.apiDomain + 'upload/editor-image');
    }
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }
}
